<template>
  <div>
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-spinner
          v-show="isProgres"
          variant="primary"
          class="my-spinner"
      />
      <hr v-if="!spinner">
      <b-row
          v-show="!isProgres"
          class="mt-1"
      >
        <b-col cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Oda Kayıtları</h4>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="bgInfo"
                      class="myButton border-0 float-right"
                      to="/odaKayit"
                      :style="{backgroundColor:$store.getters.cardTitleButton}"
                  >
                    <feather-icon
                        icon="FilePlusIcon"
                        class="mr-50"
                    />
                    Yeni Oda Kaydı
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="bgInfo"
                      class="myButton mr-1 border-0 float-right"
                      to="odaTurleri"
                      :style="{backgroundColor:$store.getters.cardTitleButton}"
                  >
                    <feather-icon
                        icon="BookmarkIcon"
                        class="mr-50"
                    />
                    Oda Tipleri
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <hr>
            <table
                :key="count"
                class="table table-bordered mt-1"
                :class="width < 1000 ? 'table-responsive mt-3':''"
            >
              <thead>
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  ODA ADI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  ODA TÜRÜ
                </th>
                <th
                    scope="col"
                    class="font10"
                    :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
                >
                  PAYLAŞIMLI ODA
                </th>
                <th
                    scope="col"
                    class="font10"
                    :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
                >
                  ODA KAPATMA
                </th>
                <th
                    scope="col"
                    class="font10"
                    :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
                >
                  YATAK SAYISI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  TARİFE
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in odaListesi"
                  :key="index"
                  class="text-center"
              >
                <td width="10%">
                  {{ item.odaAdi }}
                </td>
                <td width="10%">
                  {{ item.odaTipi }}
                </td>
                <td width="1%">
                  <b-form-checkbox
                      checked="true"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      :value="item.paylasimli == 1 ? true : false"
                      @change="paylasimSet(item)"
                  >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon"/>
                      </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon"/>
                      </span>
                  </b-form-checkbox>
                </td>
                <td width="1%">
                  <b-form-checkbox
                      checked="true"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      :value="item.odaKapatma == 1 ? true : false"
                      @change="odaKapatmaSet(item)"
                  >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon"/>
                      </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon"/>
                      </span>
                  </b-form-checkbox>
                </td>
                <td width="1%">
                  {{ item.yatak }}
                </td>
                <td width="62%">
                  <b-row>
                    <b-col
                        class="tarife-css"
                        cols="12"
                    >
                      <strong
                          v-for="(veri, index) in item.tarifeBilgileri.slice(
                            0,
                            10
                          )"
                          :key="index + 1"
                          class="mr-1"
                          variant="bgInfo"
                      >{{ veri.tarifeAdi }} : {{ veri.ucret }} TL -
                      </strong>
                    </b-col>
                  </b-row>
                </td>
                <td width="15%">
                  <b-button
                      :to="'/odaResim/' + item.odaID"
                      variant="gradient-success"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                  >
                    <feather-icon icon="ImageIcon"/>
                  </b-button>
                  <b-button
                      variant="bgInfo"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      :to="'/odaOzelFiyat/' + item.odaID"
                  >
                    <feather-icon icon="DollarSignIcon"/>
                  </b-button>
                  <b-button
                      variant="bgPrimary"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      :to="'/odaUpdate/' + item.odaID"
                  >
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                  <b-button
                      variant="bgDanger"
                      class="btn-icon rounded-circle"
                      size="sm"
                      @click="odaSil(item)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import 'jquery/dist/jquery.min.js'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BSpinner,
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile,
} from 'bootstrap-vue'

import { quillEditor } from 'vue-quill-editor'
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BCardActions,
    BSpinner,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    quillEditor,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: true,
      count: 0,
      snowOption: {
        placeholder: '',
      },
      isProgres: false,
      cumle: '',
    }
  },
  computed: {
    odaListesi() {
      return this.$store.getters.odaListesiReturn
    },
  },
  created() {
    this.$store
        .dispatch('oda')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearOdalar')
  },
  methods: {
    paylasimSet(val) {
      this.$store
          .dispatch('odaPaylasimGuncelle', {
            id: val.odaID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
      this.count += 1
    },
    odaKapatmaSet(val) {
      this.$store
          .dispatch('odaKapatmaGuncelle', {
            id: val.odaID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
      this.count += 1
    },
    odaSil(val) {
      this.$swal({
        title: 'Odayı Silmek İstediğine Emin Misin?',
        text: 'Bu İşlem Geri Alınamaz',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store
                  .dispatch('odaSilWebPanel', { id: val.odaID })
                  .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
                    const mesaj = this.$store.getters.notificationSettings(res)
                    this.$toast(
                        {
                          component: ToastificationContent,
                          props: {
                            title: 'Başarılı',
                            text: mesaj,
                            icon: 'ThumbsUpIcon',
                            variant: 'success',
                          },
                        },
                        { position },
                    )
                  })
                  .catch(err => {
                    const mesaj = this.$store.getters.notificationSettings(err)
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Hata!',
                        text: mesaj,
                        icon: 'ThumbsDownIcon',
                        variant: 'danger',
                      },
                    })
                  })
            }
          })
    },
    onFileSelected(event) {
      event.target.files.forEach((image, index) => {
        this.selected.push(image)
      })
    },
    formatNames(files) {
      return files.length === 1
          ? files[0].name
          : `${files.length} resim seçildi`
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.tarife-css {
  padding: 0 20px !important;
}

.margin-0-auto {
  margin: 0 auto !important;
}

.table td {
  font-weight: 900 !important;
}

.my-spinner {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.odaKayitRow {
  justify-content: space-around !important;
}

.table td[data-v-199ebc45][data-v-199ebc45] {
  text-align: center;
}

.myColor {
  width: 30px;
  height: 30px;
  border-radius: 14px;
  margin: 0 auto !important;
}

.row {
  justify-content: end;
}

[dir] .table th,
[dir] .table td {
  padding: 3px;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white;
  padding: 3px !important;
  border: none;
  text-align: center;
}

[dir] .table th,
[dir] .table td {
  padding: 3px !important;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.my-colsss {
  justify-content: space-between;
  display: flex;
}

.jusent {
  justify-content: end !important;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white;
}

.card-body {
  padding: 10px 15px;
}

.font10 {
  font-size: 10px !important;
}
</style>
